// Colors
$light-grey: #f8f7f7;
$dark-grey: #2b2b2b;
$dark-blue: #012b31;
$blue: #1d8797;
$light-blue: #8ecfd6;
$lightest-blue: #dff3f8;
$white: #fdfdfd;
// Font Size
$font-size-xlarge: 2.4rem;
$font-size-large: 1.8rem;
$font-size-medium: 1.6rem;
$font-size-small: 1.4rem;
// Spacing
$xs-size: .6rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$header-size: 11.1rem;
$desktop-breakpoint: 45rem;
$min-width1: 70rem;
$min-width2: 80rem;
$min-width3: 100rem;
// Sizing
$logo-height: 7.2rem;
// Transition:
$transition: all 0.3s ease;
// Letter Spacing
$letter-spacing: 0.5px;