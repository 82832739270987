.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $m-size $l-size;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: $transition;

  &__left {
    align-items: center;
    display: flex;
  }

  &__image {
    height: $logo-height;
  }

  &__link {
    color: $dark-grey;
    margin-left: $l-size;
    padding: $xs-size;
    text-decoration: none;

    &--active {
      span {
        color: $blue;   
      } 
    } 

    span::before {
      border-bottom: 2px solid $blue;
      position: absolute;
      content: attr(data-content);
      color: $blue;
      clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
      transition: $transition;
    }  
    &:hover span::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  &__login-link {
    background: $white;
    border-radius: $xs-size;
    color: $dark-grey;
    padding: $xs-size $l-size;
    text-decoration: none;
    transition: $transition;
    &:hover {
      color: $blue;
    }
  }

  &__login-link--blue {
    background: $blue;
    border-radius: $xs-size;
    color: $white;
    padding: $xs-size $l-size;
    text-decoration: none;
    transition: $transition;
    &:hover {
      background: rgba($blue, .9)
    }
  }

  &__colored {
    background: $white;
  }
}

.white {
  background: $white;
}