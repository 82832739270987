* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: $white;
  color: $dark-grey;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, Arial, sans-serif;
  font-size: $font-size-medium;
  line-height: 1.6;
  letter-spacing: $letter-spacing;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

textarea:focus, input:focus{
    outline: none;
}