.about-us {
  height: 95vh;
  width: 100vw;
  object-fit: cover;

  &__bg-image {
    height: 95vh;
    object-fit: cover;
    position: absolute;
    width: 100vw;
    z-index: -2;
  }

  &__color-layer {
    background: rgba($dark-blue, .90);
    height: 95vh;
    position: absolute;
    width: 100vw;
    z-index: -1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding-top: $header-size;
  }

  &__title {
    color: $white;
    font-size: $font-size-xlarge;
    font-weight: 500;
    margin: $xl-size 0;
    display: table;
    white-space: nowrap;
    overflow: hidden;
    &:before, &:after {
      border-top: 2px solid $light-blue;
      content: '';
      display: table-cell;
      position: relative;
      top: $font-size-large;
      width: 45%;
    }
    &:before { right: 2.5%; }
    &:after { left: 2.5%; }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 50vh;
    max-width: 80vw;
  }

  &__story {
    display: flex;
    flex-direction: column;
    margin-right: 10rem;
    max-width: 35vw;
    &__title {
      color: $white;
      font-size: $font-size-xlarge;
      font-weight: 500;
      margin-bottom: $m-size;
    }
    &__text {
      color: $white;
      font-size: $font-size-medium;
      font-weight: 300;
    }
  }

  &__image {
    height: 30rem;
    border-radius: $m-size;
    box-shadow: 8px 8px $light-blue;
  }
}