.contact {
  align-items: center;
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(15, 1fr);

  &__info {
    grid-area: 1 / 2 / 1 / span 6;
    z-index: 2;
  }

  &__title {
    font-size: $font-size-xlarge;
    font-weight: 500;
    margin: 0 $xs-size;
  }

  &__text {
    font-size: $font-size-small;
    font-weight: 300;
    margin: 0 $xs-size $m-size $xs-size;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 10rem;
  }

  &__form-item--small {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    font-weight: 300;
    grid-column: span 1;
    margin: $xs-size;   
  }

  &__form-item--big {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    font-weight: 300;
    grid-column: span 2;
    margin: $xs-size;
  }

  &__input {
    background: $lightest-blue;
    border: none;
    border-radius: $xs-size;
    font-size: $font-size-medium;
    font-weight: 300;
    padding: $s-size;
  }

  &__text-area {
    background: $lightest-blue;
    border: none;
    border-radius: $xs-size;
    font-size: $font-size-medium;
    font-weight: 300;
    grid-column: span 2;
    justify-self: stretch;
    height: 10rem;
    padding: $s-size;
    resize: none;
  }

  &__button {
    background: $blue;
    border: none;
    border-radius: $xs-size;
    color: $white;
    justify-self: start;
    margin: $xs-size;
    padding: 10px $l-size;
    text-decoration: none;
    text-align: center;
    transition: $transition;
    &:hover {
      background: rgba($blue, .9)
    }
  }

  &__image-container {
    height: 100vh;
    grid-area: 1 / 7 / 1 / span 9;
    overflow: hidden;
  }

  &__image {
    height: 100vh;
    opacity: 90%;
  }

  &__triangle {
    border-top: 100vh solid $white;
    border-right: 300px solid transparent;
    grid-area: 1 / 7 / 1 / span 2;
    z-index: 1;
  }
}