.hero {
  align-items: center;
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(15, 1fr);

  &__underline {
    width: 5rem;
    height: 0.25rem;
    margin: 0 auto 0.5rem 0;
    background: $blue;
  }
    
  &__info {
    grid-area: 1 / 2 / 1 / span 6;
    z-index: 2;
  }

  &__text {
    font-size: $font-size-xlarge;
    font-weight: 500;
    margin-bottom: $l-size;
  }

  &__button {
    background: $blue;
    border: none;
    border-radius: $xs-size;
    color: $white;
    padding: 10px $l-size;
    text-decoration: none;
    transition: $transition;
    &:hover {
      background: rgba($blue, .9)
    }
  }

  &__image-container {
    height: 100vh;
    grid-area: 1 / 7 / 1 / span 9;
    overflow: hidden;
  }

  &__image {
    height: 100vh;
    opacity: 90%;
  }

  &__triangle {
    border-top: 100vh solid $white;
    border-right: 300px solid transparent;
    grid-area: 1 / 7 / 1 / span 2;
    z-index: 1;
  }
}