.features {
  align-items: center;
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
  padding: 0 $m-size;
  padding-top: $header-size;
  margin: 0 auto;
  max-width: 80vw;

  &__title {
    font-size: $font-size-xlarge;
    font-weight: 500;
    grid-area: 2/8/2/8;
    overflow: hidden;
    justify-self: center;
  }

  &__underline {
    width: 5rem;
    height: 0.25rem;
    margin: 0 auto $m-size auto;
    background: $blue;
  }
}

.feature-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-row: 8 / 3 span;
  grid-column: 5 span;

  &__title {
    text-align: center;
    background: $dark-blue;
    border-radius: $xs-size;
    color: $white;
    margin-bottom: $l-size;
    padding: $xs-size 0;
    width: 18rem;
  }

  &__image {
    border-radius: $xs-size;
    height: 20rem;
  }

  &__text {
    text-align: center;
    display: flex;
    font-weight: 300;
    justify-content: space-between;
    margin: $l-size $l-size 0 $l-size;
  }
}