.founders {
  align-items: center;
  background: $light-grey;
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  &__title {
    font-size: $font-size-xlarge;
    font-weight: 500;
    margin: $xl-size 0;
    display: table;
    white-space: nowrap;
    overflow: hidden;
    &:before, &:after {
      border-top: 2px solid $light-blue;
      content: '';
      display: table-cell;
      position: relative;
      top: $font-size-large;
      width: 45%;
    }
    &:before { right: 2.5%; }
    &:after { left: 2.5%; }
  }

  &__container {
    display: flex;
  }
}

.founder-card {
  background: $white;
  border-radius: $m-size;
  display: flex;
  flex-direction: column;
  margin: 0 $l-size;
  padding: $l-size;
  width: 30vw;

  &__image {
    place-self: center;
    border: $xs-size solid $light-blue;
    border-radius: 50%;
    height: 20rem;
    width: 20rem;
    margin-bottom: $m-size;
  }

  &__name {
    font-size: $font-size-xlarge;
    font-weight: 300;
    text-align: center;
    margin-bottom: $m-size;
  }

  &__text {
    font-size: $font-size-medium;
    font-weight: 300;
    text-align: center;     
  }
}