.admin-login {
  height: 90vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__box {
    align-items: center;
    background-color: $light-blue;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin: $s-size;
    padding: $s-size $m-size;
  }

  &__title {
    font-size: $font-size-xlarge;
    font-weight: 600;
  }

  &__input {
    background: transparent;
    border: none;
    border-bottom: 4px solid $white;
    color: $dark-grey;
    font-size: $xl-size;
    font-weight: 600;
    margin: $s-size $m-size;
    outline: none;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 6rem;
  }

  &__text {
    font-size: $font-size-small;
  }

  &__error {
    font-style: italic;
    font-size: $font-size-small;
  }
}