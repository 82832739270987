.ecosystem {
  align-items: center;
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(15, 1fr);
  padding-top: $header-size;

  &__image {
    width: 100%;
    grid-area: 1/1/1/10;
  }

  &__info {
    grid-area: 1/10/1/15;
    display: flex;
    flex-direction: column;
  }

  &__title {
    align-self: center;
    font-size: $font-size-xlarge;
    font-weight: 500;
  }

  &__underline {
    width: 5rem;
    height: 0.25rem;
    margin: 0 auto $l-size auto;
    background: $blue;
  }

  &__text {
    align-items: center;
    display: flex;
    font-weight: 300;
    justify-content: space-between;
    margin-bottom: $l-size;
  }

  &__circle-1 {
    background: $light-blue;
    border-radius: 50%;
    padding: 25px;
    margin-right: $l-size;
  }

  &__circle-2 {
    background: $blue;
    border-radius: 50%;
    padding: 25px;
    margin-right: $l-size;
  }

  &__circle-3 {
    background: $dark-blue;
    border-radius: 50%;
    padding: 25px;
    margin-right: $l-size;
  }
}
