.arrow-up {
  align-self: start;
  background: none;
  border: none;
  cursor: pointer;
  color: $dark-blue;
  font-size: $font-size-xlarge;
  justify-self: center;
  grid-area: 1/8/1/8;
  z-index: 2;
} 

.arrow-down {
  align-self: end;
  background: none;
  border: none;
  cursor: pointer;
  color: $dark-blue;
  font-size: $font-size-xlarge;
  justify-self: center;
  grid-area: 1/8/1/8;
  margin-bottom: $m-size;
  z-index: 2;
}