.missions {
  display: flex;
  flex-direction: column;
  padding: $xl-size;
}

.mission-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: $l-size;

  &__title {
    align-self: center;
    background: url('../../assets/rectangle-light-blue.png'), url('../../assets/rectangle-white.png'), url('../../assets/rectangle-blue.png');
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: contain, contain, contain;
    background-position: left , center,  right;
    color: $white;
    font-size: $font-size-xlarge;
    font-weight: 300;
    margin-right: 12rem;
    padding: $m-size;
    padding-right: 7.2rem;
    text-align: center;
    width: 30rem;
  }

  &__text {
    width: 50vw;
  }
}